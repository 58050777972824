import React from "react";
import axios from "axios";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import BackAndBreadCrmOneParent from "./BackAndBreadCumOneParent";
import ReactPaginate from "react-paginate";
import LoaderRounded from "./LoaderRounded";
import { Button, Col, Form, Nav, Row, Tab } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons"

class Electioncounttable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      colleges: [],
      errors: {},
      CityData: [],
      districtsLocal: JSON.parse(localStorage.getItem("districts")),
      currentPage: "",
      counts: "",
      totalPages: "",
      year: "",
      years: [],
      role: "",
      rejectCandidateList: "",
      rejectCandidateCounts: "",
      selectedYear: null,
      previousLimits: 100,
      renderPageLimit: 10,
      selectedPage: 0,
      electionListCounts: 0,
      loader: false,
      loading: true,
      key: "AllCandidate",
    };
    this.handlePageChange = this.handlePageChange.bind(this);
    this.getCollegeWithCity = this.getCollegeWithCity.bind(this);
    this.getCollegeWithYear = this.getCollegeWithYear.bind(this);
  }

  componentWillMount() {
    setTimeout(() => this.setState({ loading: false }), 1000);
  }

  handlePageChange(page) {
    this.setState({ selectedPage: page.selected });
  }

  UNSAFE_componentWillMount() {
    this.getData();
    this.getRejectedData()
    this.showCity()
  }

  showCity() {
    let districts = JSON.parse(localStorage.getItem("districts"));
    if (districts[0] !== "*") {
      this.setState({
        CityData: districts,
      });
    } else {
      axios.get(process.env.REACT_APP_APIURL + `/address/city/MH`)
        .then((response) => {
          if (Array.isArray(response.data) && response.data.length > 0) {
            this.setState({
              CityData: response.data,
            });
          }
        })
        .catch((error) => {
          console.log("show city error", error);
        });
    }
  }

  // year dropdown API
  componentDidMount() {
    let role = localStorage.getItem("role");
    this.setState({ role });

    const currentYear = new Date().getFullYear();
    const initialYears = this.generateYears(2021, currentYear);
    this.setState({ years: initialYears });
  }

  generateYears(start, end) {
    const yearsArray = [];
    for (let year = end; year >= start; year--) {
      yearsArray.push(year);
    }
    return yearsArray;
  }

  handleCompleteYear = () => {
    const currentYear = new Date().getFullYear();
    this.setState((prevState) => ({
      years: [...prevState.years, currentYear + 1],
    }));
  };

  // get college data
  getData(selected_distict, selected_year) {
    let currentComponent = this;
    this.setState({
      loader: true,
    });
    // const currentDate = new Date();
    // const currentYear = currentDate.getFullYear();
    // const newYear = parseInt(selected_year);
    let districts;
    if (selected_distict === "All") {
      districts = JSON.parse(localStorage.getItem("districts"));
    } else {
      districts = [selected_distict];
    }

    axios.post(process.env.REACT_APP_APIURL + `/elctionuser/get/allcount/year/college`,
      {
        year: selected_year ? parseInt(selected_year) : "",
        city: selected_distict ? districts : this.state.districtsLocal
      })
      .then((res) => {
        let ElectionList = res.data.data.data;
        let ElectionListCount = res.data.data.count;
        currentComponent.setState({
          colleges: ElectionList,
          loader: false,
          electionListCounts: ElectionListCount
        });
      })
      .catch((error) => {
        currentComponent.setState({ loader: false, })
        console.log("error", error);
      })
  }

  // get rejected data
  getRejectedData(selected_distict, selected_year) {
    let currentComponent = this;
    this.setState({
      loader: true,
    });
    let districts;
    if (selected_distict === "All") {
      districts = JSON.parse(localStorage.getItem("districts"));
    } else {
      districts = [selected_distict];
    }

    axios.get(process.env.REACT_APP_APIURL + `/coordinator/user/college_id/rejected/idcard?cities=${selected_distict ? districts : this.state.districtsLocal}&year=${selected_year ? selected_year : ""}`)
      .then((res) => {
        let RejectedList = res.data.data
        let RejectedListCount = res.data.count
        currentComponent.setState({
          rejectCandidateList: RejectedList,
          loader: false,
          rejectCandidateCounts: RejectedListCount
        })
      })
  }

  // handle city onChange
  getCollegeWithCity(e) {
    this.setState({
      selected_city: e.target.value,
    });
    this.getData(e.target.value, this.state.year);
    this.getRejectedData(e.target.value, this.state.year)
  }

  // handle year onChange
  getCollegeWithYear(e) {
    this.setState({
      year: e.target.value,
    }, () => {
      this.getData(this.state.selected_city, e.target.value);
      this.getRejectedData(this.state.selected_city, e.target.value)
    });
  }

  searchFromList(event) {
    this.setState({
      coordinator: event.target.value,
      currentFilterBy: event.target.name,
    });
  }

  render() {
    let election_list = "";
    let candidate_list = "";
    if (this.state.colleges.length > 0) {
      election_list = this.state.colleges.map((college, index) => (
        <tr key={college._id}>
          <td>{index + 1}</td>
          <td>{college._id}</td>
          <td><span className="circle">
            {college.count} </span>
          </td>
        </tr>
      ));
    }
    if (this.state.rejectCandidateList.length > 0) {
      candidate_list = this.state.rejectCandidateList.map((itm, index) => (
        <tr key={itm._id}>
          <td>{index + 1}</td>
          <td>{itm?.first_name + " " + itm?.last_name}</td>
          <td>{itm?.mobile}</td>
          <td>{itm?.college_name}</td>
          <td>
            <div className='d-flex flex-column'>
              <img src={itm.college_id[0] ? itm.college_id[0] : require("../Assets/images/noidcard.jpg").default}
                alt="Government Id Card" style={{ marginRight: "10px", width: "100px", height: "100px" }} loading="lazy" />
              <div style={{ alignSelf: "center", textAlign: "center" }}>
                <a href={itm.college_id[0]} className="btn btn-secondary btn-sm" target="_blank" rel="noreferrer"
                  style={{ backgroundColor: "#003147", color: "white" }}>Open image</a>
              </div>
            </div>
          </td>
          <td>
            <div className='d-flex flex-column'>
              <img src={itm.government_id[0] ? itm.government_id[0] : require("../Assets/images/noidcard.jpg").default}
                alt="Government Id Card" style={{ marginRight: "10px", width: "100px", height: "100px" }} loading="lazy" />
              <div style={{ alignSelf: "center", textAlign: "center" }}>
                <a href={itm.government_id[0]} className="btn btn-secondary btn-sm" target="_blank" rel="noreferrer"
                  style={{ backgroundColor: "#003147", color: "white" }}>Open image</a>
              </div>
            </div>
          </td>

          <td style={{ color: "#ff0026" }}>{itm?.isVoterIdCardStatus}</td>
        </tr>
      ))
    }
    //  else{
    //   election_list = (
    //     <tr>
    //       <td colSpan="6" className="text-center">No Data Found....</td>
    //     </tr>
    //   );
    // }

    let cities;
    // const CityData = {};
    if (this.state.districtsLocal[0] !== "*") {
      cities = Array.isArray(this.state.CityData)
        ? this.state.CityData?.map((e, key) => {
          return (
            <option key={key} value={e}>
              {e}
            </option>
          );
        })
        : null;
    } else {
      cities = this.state.CityData?.map((e, key) => {
        return (
          <option key={key} value={e.name}>
            {e.name}
          </option>
        );
      });
    }

    // get users district data
    const getDistData = localStorage.getItem("districts");
    const districtData = JSON.parse(getDistData);

    return (
      <>
        <Sidebar />
        <div className="main">
          <div className="topbar">
            <Navbar />
          </div>
          <div className="container">
            <BackAndBreadCrmOneParent
              backLink=""
              backName=""
              currentTabName="Election List"
            />
            <h2 className="text-center mb-0">Voter List Counts</h2>
          </div>

          <br></br>

          {
            this.state.role === "COORDINATOR" ?
              <Col md={12}>
                <Tab.Container activeKey={this.state.key} onSelect={(e) => this.setState({ key: e })}>
                  <Nav variant="pills" className="flex-row justify-content-end mx-auto">
                    <Nav.Item>
                      <Nav.Link eventKey="AllCandidate">
                        <Icon.People className='me-2' />View All Candidate</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="RejectedCandidate">
                        <Icon.People className='me-2' />View Rejected Candidate</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Row md={12} className="mx-4 mt-4">
                    <Col md={6}>
                      {
                        this.state.key === "RejectedCandidate" ?
                          <h4>All Count: {this.state.rejectCandidateCounts}</h4> :
                          <h4>All Count: {this.state.electionListCounts}</h4>
                      }

                    </Col>

                    <Col md={6}>
                      <Row md={12} className="justify-content-end w-100">
                        <Col md={4} className="d-flex">
                          <select
                            name="year"
                            className="btn dropdown-toggle mb-2 w-100"
                            style={{ backgroundColor: "#003147", color: "white" }}
                            onChange={this.getCollegeWithYear}
                            value={this.state.year}>
                            <option value="">All Years</option>
                            {this.state.years.map((year) => (
                              <option key={year} value={year}>
                                {year}
                              </option>))}
                          </select>
                        </Col>

                        <Col md={4} className="">
                          <select
                            type="button"
                            name="city"
                            className="btn dropdown-toggle w-100"
                            style={{ backgroundColor: "#003147", color: "white" }}
                            onChange={this.getCollegeWithCity}
                            id="district">
                            <option value="All">All</option>
                            {cities}
                          </select>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <Tab.Content className="mt-4">
                    <Tab.Pane eventKey="AllCandidate">
                      <div className="container">
                        <div className="row">
                          <div className="col-12">
                            <div className="table-responsive-sm">
                              <table className="table table-bordered table-responsive">
                                <thead>
                                  <tr>
                                    <th>No.</th>
                                    <th>College Name</th>
                                    <th>Count</th>
                                  </tr>
                                </thead>

                                <tbody>
                                  {this.state.loader ? (<LoaderRounded />) :
                                    election_list.length > 0 ?
                                      election_list.slice(
                                        this.state.selectedPage *
                                        this.state.renderPageLimit,
                                        this.state.renderPageLimit +
                                        this.state.selectedPage *
                                        this.state.renderPageLimit
                                      ) : <tr><td colSpan="6" className="text-center">No Data Found....</td></tr>}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab.Pane>

                    <Tab.Pane eventKey="RejectedCandidate">
                      <div className="container">
                        <div className="row">
                          <div className="col-12">
                            <div className="table-responsive-sm">
                              <table className="table table-bordered table-responsive">
                                <thead>
                                  <tr>
                                    <th>No.</th>
                                    <th>Candidate Name</th>
                                    <th>Mobile</th>
                                    <th>College Name</th>
                                    <th>College Id</th>
                                    <th>Government Id</th>
                                    <th>Status</th>
                                  </tr>
                                </thead>

                                <tbody>
                                  {this.state.loader ? (<LoaderRounded />) :
                                    candidate_list.length > 0 ?
                                      candidate_list.slice(
                                        this.state.selectedPage *
                                        this.state.renderPageLimit,
                                        this.state.renderPageLimit +
                                        this.state.selectedPage *
                                        this.state.renderPageLimit
                                      ) : <tr><td colSpan="6" className="text-center">No Data Found....</td></tr>}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>

              </Col> :
              <>
                <Row md={12} className="mx-4 mt-3">
                  <Col md={6} >
                    <h4>All Count: {this.state.electionListCounts}</h4>
                  </Col>

                  <Col md={6}>
                    <Row md={12} className="justify-content-end w-100">
                      <Col md={4} className="d-flex">
                        <select
                          name="year"
                          className="btn dropdown-toggle mb-2 w-100"
                          style={{ backgroundColor: "#003147", color: "white" }}
                          onChange={this.getCollegeWithYear}
                          value={this.state.year}>
                          <option value="">All Years</option>
                          {this.state.years.map((year) => (
                            <option key={year} value={year}>
                              {year}
                            </option>))}
                        </select>
                      </Col>

                      <Col md={4} className="">
                        <select
                          type="button"
                          name="city"
                          className="btn dropdown-toggle w-100"
                          style={{ backgroundColor: "#003147", color: "white" }}
                          onChange={this.getCollegeWithCity}
                          id="district">
                          <option value="All">All</option>
                          {cities}
                        </select>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <div className="container">
                  <div className="row">
                    <div className="col-12">
                      <div className="table-responsive-sm">
                        <table className="table table-bordered table-responsive">
                          <thead>
                            <tr>
                              <th>No.</th>
                              <th>College Name</th>
                              <th>Count</th>
                            </tr>
                          </thead>

                          <tbody>
                            {this.state.loader ? (<LoaderRounded />) :
                              election_list.length > 0 ?
                                election_list.slice(
                                  this.state.selectedPage *
                                  this.state.renderPageLimit,
                                  this.state.renderPageLimit +
                                  this.state.selectedPage *
                                  this.state.renderPageLimit
                                ) : <tr><td colSpan="6" className="text-center">No Data Found....</td></tr>}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </>
          }

          <ReactPaginate
            nextLabel="Next"
            previousLabel="Previous"
            pageCount={this.state.key === "AllCandidate" ? election_list.length / this.state.renderPageLimit : candidate_list.length / this.state.renderPageLimit}
            marginPagesDisplayed={3}
            pageRangeDisplayed={5}
            containerClassName="pagination"
            activeClassName="btn btn-primary"
            pageClassName="btn"
            previousClassName="btn"
            nextClassName="btn"
            onPageChange={this.handlePageChange}
          />
        </div>
      </>
    );
  }
}

export default Electioncounttable;
