import React from "react";
import { Link, Redirect } from "react-router-dom";
import axios from "axios";
import { Edit, Add } from "@material-ui/icons";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import candidate_old from "../data/static-candidate";
import BackAndBreadCrmOneParent from "./BackAndBreadCumOneParent";
import LoaderRounded from "./LoaderRounded";
import ReactPaginate from "react-paginate";
import LoaderRoundedWithText from "./LoaderRoundedWithText";
import { Col, Form, Row } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import moment from "moment";
class CandidateList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentFilter: null,
      candidate_old: candidate_old,
      candidate: [],
      CityData: [],
      selectValue: "all",
      phase: "",
      redirect: false,
      currentPage: "",
      counts: "",
      totalPages: "",
      previousLimits: 1000,
      renderPageLimit: 50,
      selectedPage: 0,
      allpage: "",
      districtsLocal: JSON.parse(localStorage.getItem("districts")),
      selected_city:  this.props?.location?.state?.param2 || JSON.parse(localStorage.getItem("districts")),
      limitVal: 10,
      skip_value: 0,
      page_selected: 0,
      phase: null,
      fromdate: null,
      todate: null,
      filterString: "",
      year: this.props?.location?.state?.param1 || "",
      years: [],
      filterdate: null,
      loader: false,
      loading: true,
    };

    this.applyFiter = this.applyFilter.bind(this);
    this.setFilter = this.setFilter.bind(this);
    this.Editcandidate = this.Editcandidate.bind(this);
    this.searchFromList = this.searchFromList.bind(this);
    this.getCandidateWithCity = this.getCandidateWithCity.bind(this);
    this.hanldeRowchange = this.hanldeRowchange.bind(this);
    this.getPhaseWise = this.getPhaseWise.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.searchList = this.searchList.bind(this);
    this.getCollegeWithYear = this.getCollegeWithYear.bind(this);
    this.hanldedivchange = this.hanldedivchange.bind(this);
  }

  componentWillMount() {
    setTimeout(() => this.setState({ loading: false }), 1000);
  }

  searchList(char) {
    let f = this.state.filterdata?.filter(
      (item) =>
        item?.yin_id
          ?.toString()
          .toLowerCase()
          ?.startsWith(char?.toLowerCase()) ||
        item?.mobile
          ?.toString()
          .toLowerCase()
          ?.startsWith(char?.toLowerCase()) ||
        item?.gender
          ?.toString()
          .toLowerCase()
          ?.startsWith(char?.toLowerCase()) ||
        item?.city?.toString().toLowerCase()?.startsWith(char?.toLowerCase()) ||
        item?.college_name
          ?.toString()
          .toLowerCase()
          ?.startsWith(char?.toLowerCase()) ||
        item?.college_code
          ?.toString()
          .toLowerCase()
          ?.startsWith(char?.toLowerCase()) ||
        item?.first_name
          ?.toString()
          .toLowerCase()
          ?.startsWith(char?.toLowerCase()) ||
        item?.last_name
          ?.toString()
          .toLowerCase()
          ?.startsWith(char?.toLowerCase())
    );
    this.setState({
      candidate: f,
    });
  }

  applyFilter(arr) {
    if (!this.state.currentFilter) {
      return arr;
    }
    let filteron = this.state.currentFilterBy;
    if (filteron === "city") {
      return arr.filter((x) => x.city === this.state.currentFilter);
    } else {
      let a = this.state.currentFilter.toLowerCase();
      var condition = new RegExp(a);
      var result = arr.filter(function (el) {
        return (
          condition.test(el.first_name.toLowerCase()) ||
          condition.test(el.last_name.toLowerCase())
        );
      });
      return result;
    }
  }

  handlePageChange(page) {
    let val = page.selected === 0 ? 0 : page.selected * this.state.limitVal;
    this.setState({
      skip_value: val,
      page_selected: page.selected,
    });
    this.getData(
      this.state.selected_city,
      this.state.phase,
      this.state.year,
      val,
      this.state.limitVal,
      this.state.filterdate
    );

    //this.getData();
  }

  setFilter(e) {
    e.preventDefault();
    this.setState({
      currentFilter: e.target.value,
    });
  }

  UNSAFE_componentWillMount() {
    this.getData(
      this.state.selected_city,
      this.state.phase,
      this.state.year,
      this.state.skip_value,
      this.state.limitVal,
      this.state.filterdate
    );
    this.showCity();
  }

  // year dropdown code
  componentDidMount() {
    const currentYear = new Date().getFullYear();
    const initialYears = this.generateYears(2021, currentYear);
    this.setState({ years: initialYears });
  }

  generateYears(start, end) {
    const yearsArray = [];
    for (let year = end; year >= start; year--) {
      yearsArray.push(year);
    }
    return yearsArray;
  }

  handleCompleteYear = () => {
    const currentYear = new Date().getFullYear();
    this.setState((prevState) => ({
      years: [...prevState.years, currentYear + 1],
    }));
  };

  // handle year onChange
  getCollegeWithYear = (event) => {
    const getDistData = localStorage.getItem("districts");
    const districtData = JSON.parse(getDistData);
    const getNewDistData = districtData?.map((itm, index) => {
      return itm;
    });
    this.setState({
      year: event.target.value,
    });
    this.getData(
      this.state.selected_city,
      this.state.phase,
      // this.state.fromdate,
      // this.state.todate,
      event.target.value,
      this.state.skip_value,
      this.state.limitVal,
      this.state.filterdate
    );
  };

  getData(selected_city, phase, year, skip, limit, date) {
    let currentComponent = this;
    let new_date = date ? moment(date).format("DD-MM-YYYY") : "";
    const splitdate = new_date.split("-");
    const mDate =
      splitdate[0]?.indexOf(0) == 0
        ? splitdate[0] % 10
        : parseInt(splitdate[0]);

    const mMonth =
      splitdate[1]?.indexOf(0) == 0
        ? splitdate[1] % 10
        : parseInt(splitdate[1]);
    const mYear = parseInt(splitdate[2]);
    let splitvaluedate = `${mDate}-${mMonth}-${mYear}`;
    // let a = JSON.parse(localStorage.getItem("districts"));
    // let subcity = localStorage.getItem("sub_city_name");
    this.setState({
      loader: true,
    });
    // /candidate/list/all?limit=1000&skips=0&year=2023&cities=Akola,Pune
    axios.get(process.env.REACT_APP_APIURL + `/candidate/list/all?limit=${limit ? limit : ""}&skips=${skip}&year=${
            year ? year : ""
          }&cities=${selected_city ? selected_city : ""}&date=${
            new_date ? splitvaluedate : ""
          }`
      )
      .then((res) => {
        currentComponent.setState({
          loader: false,
        });
        let responses = res.data.data;
        if (res.data.message) {
          // alert(res.data.message);
          currentComponent.setState({
            allpage: 0,
            candidate: [],
            filterdata: [],
          });
        }
        if (res.data.data) {
          let con = Math.ceil(
            parseInt(res.data.allCount) / this.state.limitVal
          );

          currentComponent.setState({
            allpage: con,
            candidate: responses,
            filterdata: responses,
          });
        }
      })
      .catch((err) => {
        currentComponent.setState({
          loader: false,
        });
        console.log(err);
      });
  }

  showCity() {
    let districts = JSON.parse(localStorage.getItem("districts"));
    if (districts[0] !== "*") {
      this.setState({
        CityData: districts,
      });
    } else {
      axios.get(process.env.REACT_APP_APIURL + `/address/city/MH`)
        .then((response) => {
          if (Array.isArray(response.data) && response.data.length > 0) {
            this.setState({
              CityData: response.data,
            });
          }
        })
        .catch((error) => {
          console.log("error in showCity", error);
        });
    }
  }

  Editcandidate(yinID, candidateID) {
    localStorage.setItem("candidate_id", candidateID);
    localStorage.setItem("yin_id", yinID);
    this.setState({
      redirect: true,
    });
  }

  getCandidateWithCity(event) {
    let selected_city =
      event.target.value === "All"
        ? this.state.selected_city
        : event.target.value;
    this.setState({
      selected_city: event.target.value,
    });
    this.getData(
      event.target.value,
      this.state.phase,
      this.state.year,
      this.state.skip_value,
      this.state.limitVal,
      this.state.filterdate
    );
  }

  hanldedivchange(event){
    this.setState({
      limitVal: event,
      selectedPage: 0,
    });
    this.getData(
      this.state.selected_city,
      this.state.phase,
      this.state.year,
      this.state.skip_value,
      event,
      this.state.filterdate
    );
  }
  
  hanldeRowchange(event) {
    this.setState({
      limitVal: event,
      selectedPage: 0,
    });
    this.getData(
      this.state.selected_city,
      this.state.phase,
      this.state.year,
      this.state.skip_value,
      event,
      this.state.filterdate
    );
  }

  searchFromList(event) {
    this.setState({
      currentFilter: event.target.value,
      currentFilterBy: event.target.name,
    });
  }

  getPhaseWise(event) {
    let eventValue =
      event.target.value == "--Default Phase--" ? null : event.target.value;
    this.setState({
      phase: eventValue,
    });
    this.getData(
      this.state.selected_city,
      eventValue,
      // this.state.fromdate,
      // this.state.todate,
      this.state.year,
      this.state.skip_value,
      this.state.limitVal,
      this.state.filterdate
    );
    // this.getData(event.target.value);
  }

  render() {
    const backlink = localStorage.getItem("setbacklink");

    if (this.state.redirect) {
      return <Redirect to={{ pathname : '/edit_candidate', state: {year : this.state.year,
      city : this?.state?.selected_city === JSON.parse(localStorage.getItem("districts")) ? JSON.parse(localStorage.getItem("districts")) : this?.state?.selected_city}}} />;
    }

    if (!localStorage.getItem("mobile")) {
      return <Redirect to="/login" />;
    }

    let candidateList = "";
    if (this.state.candidate.length > 0) {
      candidateList =
        this.state.candidate &&
        this.applyFilter(this.state.candidate).map((candidates, id) => (
          <tr key={id}>
            <td>{this.state.page_selected * this.state.limitVal + id + 1}</td>
            {/* <td>{candidates.yin_id}</td> */}
            <td>
              {candidates.first_name} &nbsp;{candidates.last_name}
            </td>
            {/* <td>{candidates.college_code}</td> */}
            <td>{candidates.college_name}</td>
            <td className="noselect">{candidates.mobile}</td>
            <td>{candidates.gender}</td>
            <td>{candidates.city}</td>
            <td>{candidates.electionYear}</td>
            {candidates.application_status === "APPROVED" ? (
              <td className="text-success">APPROVED</td>
            ) : (
              ""
            )}
            {candidates.application_status === "REJECTED" ? (
              <td className="text-danger">REJECTED</td>
            ) : (
              ""
            )}
            {candidates.application_status === "PENDING" ? (
              <td className="text-warning">PENDING</td>
            ) : (
              ""
            )}
            {candidates.application_status === "LDPNOTAPPLIED" ? (
              <td className="text-info">LDP NOT APPLIED</td>
            ) : (
              ""
            )}
            <td className="icanostyle text-center">
              <span
                onClick={() =>
                  this.Editcandidate(
                    candidates.yin_id,
                    localStorage.setItem(
                      "editcaollegecode",
                      candidates.college_code
                    )
                  )
                }
              >
                <button className="btn btn-outline-info btn-sm me-2">
                  <Edit />
                </button>
              </span>
            </td>
          </tr>
        ));
    } else {
      candidateList = (
        <tr>
          <td colSpan="8" className="text-center">
            No Data Found....
          </td>
        </tr>
      );
    }

    let cities;
    if (this.state.districtsLocal[0] !== "*") {
      cities = this.state.CityData.map((e, key) => {
        return (
          <option key={key} value={e}>
            {e}
          </option>
        );
      });
    } else {
      cities = this.state.CityData?.map((e, key) => {
        return (
          <option key={key} value={e.name}>
            {e.name}
          </option>
        );
      });
    }

    // get users district data
    const getDistData = localStorage.getItem("districts");
    const districtData = JSON.parse(getDistData);
    const getNewDistData = districtData?.map((itm, index) => {
      return itm;
    });

    return (
      <>
        {this.state.loading ? (
          <LoaderRoundedWithText />
        ) : (
          <div>
            <Sidebar />
            <div className="main">
              <div className="topbar">
                <Navbar title="All Candidate List" />
              </div>

              <Row md={12} className="mx-4 mt-4">
                <Col md={5} className="mb-2 mb-sm-0">
                  <input
                    type="text"
                    placeholder="Search here"
                    name="search"
                    value={this.state.filterString}
                    onChange={(e) => {
                      this.setState({ filterString: e.target.value });
                      this.searchList(e.target.value);
                    }}
                    className="form-control w-75 w-sm-100"
                  ></input>
                </Col>

                <Col md={7} style={{ justifyContent: "flex-end" }}>
                  <Row md={12}>
                    {/* <Col md={3} className="mb-2 mb-sm-0">
                      <select
                        name="year"
                        className="btn dropdown-toggle mb-2 w-100"
                        style={{ backgroundColor: "#003147", color: "white" }}
                        onChange={this.getCollegeWithYear}
                        value={this.state.year}
                      >
                        <option value="">All Years</option>
                        {this.state.years.map((year) => (
                          <option key={year} value={year}>
                            {year}
                          </option>
                        ))}
                      </select>
                    </Col> */}

                    <Col md={3} className="mb-2 mb-sm-0">
                      <select
                        type="button"
                        name="city"
                        value={this.state.selected_city}
                        className="btn btn-md dropdown-toggle w-100"
                        style={{ backgroundColor: "#003147", color: "white" }}
                        onChange={this.getCandidateWithCity}
                        id="district"
                      >
                        <option value={getNewDistData}>All</option>
                        {cities}
                      </select>
                    </Col>

                    <Col md={3} className="mb-2 mb-sm-0">
                      <select
                        type="button"
                        name="phase"
                        className="btn btn-md dropdown-toggle w-100"
                        style={{ backgroundColor: "#003147", color: "white" }}
                        onChange={this.getPhaseWise}
                        id="phase"
                      >
                        <option value={null}>Default Phase</option>
                        <option value="PHASE1"> --Phase 1-- </option>
                        <option value="PHASE2"> --Phase 2-- </option>
                        <option value="PHASE3"> --Phase 3-- </option>
                      </select>
                    </Col>

                    <Col md={3} className="mb-2 mb-sm-0">
                      <Link
                        to="/Addcandidate"
                        className="btn btn-md w-100"
                        style={{ backgroundColor: "#003147", color: "white" }}
                      >
                        <Add className="me-1" />
                        Add New
                      </Link>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row md={12} className="mx-4 mt-4 mb-4">
              <Col md={6} className="d-flex">
              <Col md={4} className="mb-2 mb-sm-0">
                <Form.Control value={this.state.filterdate}
                    type="date"
                    onChange={(date) => {
                      this.setState({
                        filterdate: date.target.value,
                      });
                      this.getData(
                        this.state.selected_city,
                        this.state.phase,
                        this.state.year,
                        this.state.skip_value,
                        this.state.limitVal,
                        date.target.value
                      );
                    }}/>
                </Col>

                <Col md={2} className="mb-2 mb-sm-0 ms-2">
                  <button
                    className="btn btn-success btn-md"
                    onClick={() => {
                      this.setState({
                        filterdate: "",
                      });
                      this.getData(
                        this.state.selected_city,
                        this.state.phase,
                        this.state.year,
                        this.state.skip_value,
                        this.state.limitVal,
                        null
                      );
                    }}
                  >
                    <Icon.ArrowClockwise size={18} className="me-2"/>
                    Clear
                  </button>
                </Col>
              </Col>
                
                <Col md={6} className="mb-2 mb-sm-0 justify-content-end">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <span>Records Per Page:</span>
                    <Form.Control
                      className="w-50 ms-1"
                      id="pageselect"
                      as="select"
                      onChange={(event) =>
                        this.hanldedivchange(event.target.value)
                      }
                    >
                      <option value="10">10</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="200">200</option>
                      <option value="300">300</option>
                      <option value="500">500</option>
                      <option value="1000">1000</option>
                    </Form.Control>
                  </div>
                </Col>
              </Row>

              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <div style={{ overflowx: "auto" }}>
                      <div className="table-responsive-sm">
                        <table className="table table-bordered table-responsive table-hover">
                          <thead>
                            <tr>
                              <th>ID</th>
                              {/* <th>YIN ID</th> */}
                              <th>Name</th>
                              {/* <th>College Code</th> */}
                              <th>College Name</th>
                              <th>Mobile</th>
                              <th>Gender</th>
                              <th>City</th>
                              <th>Election Year</th>
                              <th>Status</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.loader ? (
                              <tr>
                                <td colSpan={9}>
                                  <LoaderRounded />
                                </td>
                              </tr>
                            ) : (
                              candidateList
                            )}
                            {/* {this.state.loader ? (
                              <LoaderRounded />
                            ) : (
                              candidateList?.slice(
                                this.state.selectedPage *
                                  this.state.renderPageLimit,
                                this.state.renderPageLimit +
                                  this.state.selectedPage *
                                    this.state.renderPageLimit
                              )
                            )} */}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mt-3">
                  <ReactPaginate
                    nextLabel="Next"
                    previousLabel="Previous"
                    pageCount={this.state.allpage}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    containerClassName="pagination"
                    activeClassName="btn btn-primary"
                    pageClassName="btn"
                    previousClassName="btn"
                    nextClassName="btn"
                    onPageChange={this.handlePageChange}
                  />
                </div>

                <button
                  className="reaload btn  btn-success btn-sm mt-3 mb-3"
                  onClick={() => window.location.reload(true)}
                >
                  <Icon.ArrowClockwise size={18} className="me-2" />
                  Refresh
                </button>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}
export default CandidateList;
